const menuList = [
  {
    pathName: '用户管理',
    path: 'activity',
    svgIcon: 'icon-gerenzhongxin',
    children: [
      {
        pathName: '用户列表',
        path: 'userList'
      },
      {
        pathName: '金牌列表',
        path: 'goldenReferList'
      },
      {
        pathName: '官方列表',
        path: 'officialList'
      }
      /*{
        pathName: '代理列表',
        path: 'agentList'
      },
      {
        pathName: '酒店管理',
        path: 'hotelList'
      },*/
    ]
  },
  {
    pathName: '财务管理',
    path: 'activity',
    svgIcon: 'icon-youjian',
    children: [
      {
        pathName: '用户余额',
        path: 'userWalletList'
      },
      {
        pathName: '金牌收益',
        path: 'goldenWalletList'
      }
    ]
  },
  {
    pathName: '商品管理',
    path: 'activity',
    svgIcon: 'icon-youjian',
    children: [
      // {
      //   pathName: '积分商品',
      //   path: 'goodsList'
      // },
      // {
      //   pathName: '默粉话题',
      //   path: 'articleList'
      // },
      // {
      //   pathName: '护肤知识',
      //   path: 'hufuList'
      // },
      // {
      //   pathName: '贴吧管理',
      //   path: 'tiebaList'
      // },
      {
        pathName: '商品管理',
        path: 'goodsList'
      },
      {
        pathName: '授权商品',
        path: 'authGoodsList'
      },
      {
        pathName: 'SKU预设',
        path: 'skuPreset'
      },
      {
        pathName: '商品分类',
        path: 'categoryList'
      },
      {
        pathName: 'ICON入口管理',
        path: 'iconList'
      }
      // {
      //   pathName: '团购商品',
      //   path: 'groupBayList'
      // }
    ]
  },
  /* {
    pathName: '积分管理',
    path: 'activity',
    svgIcon: 'icon-huodong',
    children: [
      {
        pathName: '积分订单',
        path: 'integralorder'
      },
      {
        pathName: '积分活动',
        path: 'integralActivity'
      },
      {
        pathName: '幸运大转盘',
        path: 'turntablesys'
      }
    ]
  }, */
  {
    pathName: '审核列表',
    path: 'audit',
    svgIcon: 'icon-huodong',
    children: [
      {
        pathName: '订单管理',
        path: 'orderReview'
      },
      // {
      //   pathName: '订单审核',
      //   path: 'orderAudit'
      // },
      /*{
        pathName: '代理审核',
        path: 'agentAudit'
      },*/
      {
        pathName: '评价审核',
        path: 'reviewAudit'
      }
    ]
  },
  {
    pathName: '配置管理',
    path: 'system',
    svgIcon: 'icon-yijian',
    children: [
      // {
      //   pathName: '积分设置',
      //   path: 'integralsys'
      // },
      {
        pathName: '品牌信息',
        path: 'brandInfoSeting'
      },
      {
        pathName: '授权商家',
        path: 'authBusiness'
      },
      {
        pathName: '轮播图',
        path: 'bannerlist'
      },
      {
        pathName: '系统设置',
        path: 'systemSetting'
      },
      {
        pathName: '管理员',
        path: 'administrator'
      }
    ]
  }
]

for (let i = 0; i < menuList.length; i++) {
  const item = menuList[i]
  item.id = `${i + 1}`
  if (item.children.length) {
    for (let j = 0; j < item.children.length; j++) {
      const child = item.children[j]
      child.id = `${item.id}-${j + 1}`
    }
  }
}
// console.log(menuList);
export default menuList
