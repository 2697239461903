import axios from 'axios'
import store from '@/store/index.js'
import router from '@/router'
import { Loading } from 'element-ui'
import { Message } from 'element-ui'
// 根据环境不同引入不同api地址
import { baseApi } from '@/config'
/**
 * 自定义全局loading
 */
let loading
let isLoading = false
// 开启loading
function startLoading() {
  //使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: '拼命加载中...',
    background: 'rgba(255,255,255,0)'
  })
}
// 关闭loading
function endLoading() {
  //使用Element loading-close 方法
  loading.close()
}

const service = axios.create({
  baseURL: baseApi,
  timeout: 10000 // 设置超时时间
})

// http请求拦截器
service.interceptors.request.use(
  config => {
    // console.log(config)
    if (config.isLoading) {
      isLoading = config.isLoading
      startLoading()
    }
    if (store.state.Login.loginInfo) {
      config.headers['token'] = store.state.Login.loginInfo.token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
//http 200 状态码下的异常map
const erorrMap = {
  500: '手机号错误',
  401: 'token失效，请重新登录',
  CMN00002: '输入参数校验失败'
}
// http响应拦截器
service.interceptors.response.use(
  res => {
    isLoading && endLoading()
    //可以根据后端的系统而相应的做调整
    let code = res.data.code
    // 导出接口
    if (res.config.url.includes('export')) {
      return requestExport(res)
    }
    if (code == 200) {
      return Promise.resolve(res.data)
    } else {
      //erorrMap[code]
      switch (code) {
        case 401:
          store.commit('Login/logout')
          router.replace('/login')
          break
        default:
          break
      }
      return Promise.reject(res.data)
    }
    return res.data
  },
  async error => {
    isLoading && endLoading()
    console.log(error)
    if (error.request) {
      if (error.request.status === 0) {
        //超时
      }
    } else if (error.response) {
      if (error.response.status === 400) {
        //请求参数有问题
      } else if (error.response.status === 404) {
        //未找到资源
      } else if (error.response.status === 401) {
        //请先登录
      } else if (error.response.status === 500) {
        //服务器异常
        console.log('服务器异常')
      }
    }
    return Promise.reject(error)
  }
)
const requestExport = (response) => {
  console.log(response);
  if (response.status === 200 && response.data) {
    let params = {
      file: response.data,
      fileName: decodeURI(response.headers['filename'])
    }
    return Promise.resolve(params)
  } else {
    Message({
      message: '请求错误' + response.status,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(response)
  }
}

export default service
