<!--  -->
<template>
  <el-aside style="width:200px">
    <el-menu :default-active="activePath" router :default-openeds="openMenu" text-color="#000" active-text-color="#fff">
      <el-submenu v-for="menu in menuList" :key="menu.id" :index="menu.id">
        <template slot="title">
          <svg class="icon" aria-hidden="true" style="fontSize:22px;">
            <use :xlink:href="'#' + menu.svgIcon" />
          </svg>
          <span>{{ menu.pathName }}</span>
        </template>
        <el-menu-item v-for="item in menu.children" :key="item.id" :index="`/${item.path}`" @click="saveNavState(`/${item.path}`)">
          {{ item.pathName }}
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
import menuList from './menuList'
export default {
  data() {
    return {
      activePath: '',
      menuList
    }
  },
  computed: {
    openMenu() {
      return this.menuList.map(item => item.id)
    }
  },
  methods: {
    saveNavState(activePath) {
      this.$sessionStorage.set('activePath', activePath)
      this.activePath = activePath
    }
  },
  mounted() {
    this.activePath = this.$sessionStorage.get('activePath') || this.$route.path
    // console.log(this.activePath)
  },
  components: {}
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.el-menu-item.is-active {
  color: #fff;
  background-color: #1296db;
}

.el-submenu .el-menu-item {
  height: 38px;
  line-height: 38px;
}

.el-menu-item,
.el-submenu__title {
  height: 44px;
  line-height: 44px;
}
</style>
