import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 获取管理员列表
 * @returns
 */
export const adminList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/admin/list/`,
      params: params,
      method: 'get'
    })
  )
}

/**
 * 重置密码
 * @param {*} "id" 用户id
 * @param {*} "pwd" 新密码
 * @returns
 */
export const updatePwd = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/admin/change/pwd`,
      method: 'post',
      data
    })
  )
}

/**
 * 添加管理员
 * @returns
 */
export const adminAdd = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/admin/add`,
      method: 'post',
      data
    })
  )
}

/**
 * 登出
 * @returns
 */
export const logout = () => {
  return awaitWrap(
    request({
      url: `/pc/sys/logout`,
      method: 'POST'
    })
  )
}
