import Vue from 'vue'


export default () => {
  // 针对 el-input做的限制，只能输入正整数
  // <el-input v-model="vlaue"  v-Int />
  Vue.directive('Int', {
    bind: function (el, binding, vnode) {
      const input = el.getElementsByTagName('input')[0] || el
      input.onkeyup = function (e) {
        if (input.value.length === 1) {
          input.value = input.value.replace(/[^0-9]/g, '')
        } else {
          input.value = input.value.replace(/[^\d]/g, '')
        }
        vnode.data.model.callback(input.value)
        // trigger(input, 'input')
      }
      input.onblur = function (e) {
        if (input.value.length === 1) {
          input.value = input.value.replace(/[^0-9]/g, '')
        } else {
          input.value = input.value.replace(/[^\d]/g, '')
        }
        vnode.data.model.callback(input.value)
        // trigger(input, 'input')
      }
    }
  })
  // 针对 el-input做的限制，保留两位小数
  // <el-input v-model="vlaue"  v-numbarFloat />
  Vue.directive("numbarFloat", {
    inserted: function (el) {
      el.addEventListener('keypress', function (e) {
        var that = this
        // 通过正则过滤小数点后两位
        e.target.value = (e.target.value.match(/^\d*(.?\d{0,1})/g)[0]) || null
      })
    }
  }
  )
  /**
   * 限制输入框输入数字 最小为0，
   * @params num: 设置小数位数，默认为0
   * <el-input v-numberInt:0="item.first_fee" v-model.trim="item.first_amount"></el-input>
   */
  Vue.directive('numberInt', {
    bind: function (el, binding, vnode) {
      const element = el.getElementsByTagName('input')[0]
      const len = binding.arg || 0 // 初始化设置 
      element.value = Number(element.value).toFixed(len)  // 失焦时候格式化
      element.addEventListener('input', function () {
        if (isNaN(element.value)) {
          vnode.data.model.callback(0)
        } else {
          vnode.data.model.callback(Number(element.value).toFixed(len))
        }
      })
    }
  })

  /**
 * 防抖
 * value 默认为1000
 * 使用实例：v-debounce="3000"
 */
  Vue.directive('debounce', {
    inserted(el, binding) {
      el.addEventListener('click', () => {
        el.style.pointerEvents = 'none'
        if (!el.disabled) {
          setTimeout(() => {
            el.style.pointerEvents = 'auto'
          }, binding.value || 1000)
        }
      })
    }
  })
}
/*********************************
  ** Fn: trigger
  ** Intro: 参考 https://github.com/vuejs/Discussion/issues/157#issuecomment-273301588
  ** Author: zyx
*********************************/
const trigger = (el, type) => {
  const e = document.createEvent('HTMLEvents')
  e.initEvent(type, true, true)
  el.dispatchEvent(e)
}
