import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.less'
import '@/styles/index.less'
import '@/assets/font/iconfont.js'
import '@/plugin/index'
// 过滤
import '@/filters/filter'
// 粒子背景
import particles from 'particles.js'
// 指令
import directive from '@/directive/index'
// 富文本
// import wangEditor from 'wangeditor'
// import 'wangeditor/release/wangEditor.min.css'
// Vue.use(wangEditor)

// 引入本地存储
import { storage, sessionStorage } from '@/utils/storage'
// 错误处理方法
import { awaitWrap } from './utils/util'
// 按需引入loadsh
import '@/utils/loadsh'
// 处理数组里嵌套对象的深拷贝
Vue.prototype.$deepCopy = data => {
  let datas = []
  data.forEach(item => {
    let { ...itemDate } = item
    datas.push(itemDate)
  })
  return [...datas]
}

//todo 引入全局组建

Vue.use(ElementUI)
Vue.use(particles)
Vue.use(directive)

Vue.config.productionTip = false

Vue.prototype.$storage = storage
Vue.prototype.$sessionStorage = sessionStorage
Vue.prototype.$awaitWrap = awaitWrap
Vue.prototype.$store = store
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
