<template>
  <div id="app">
    <router-view v-if="isRouter" />
  </div>
</template>
<script>
export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouter: true
    }
  },
  methods: {
    reload() {
      // 刷新全部
      this.isRouter = false
      this.$nextTick(function() {
        this.isRouter = true
      })
    }
  }
}
</script>
<style lang="less">
body {
  height: 100%;
}
#app {
  height: 100%;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
