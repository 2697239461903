<!--  -->
<template>
  <div class="title_bar">
    <div class="header">
      <slot>
        {{ routeName }}
      </slot>
    </div>
    <div class="handle">
      <slot name="right">
        <el-button v-if="handle.isSave" type="primary" size="small" @click="handleSave">{{ handle.saveText || '保存' }}</el-button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // routeName: String
    handle: {
      type: Object,
      default: function(value) {
        return {
          isSave: false,
          saveText: '保存'
        }
      }
    }
  },
  data() {
    return {
      routeName: ''
    }
  },
  methods: {
    routeMatch() {
      this.routeName = this.$route.matched[this.$route.matched.length - 1].name
    },
    handleSave() {
      this.$emit('handleSave')
    }
  },
  mounted() {
    this.routeMatch()
  },
  components: {}
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.title_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  background-color: #eee;
  padding: 0 20px;
  margin: 0 10px 10px 0;
  font-size: 18px;
}
</style>
