/**
 * 处理await成功失败信息
 * @param {*} promise
 */
export const awaitWrap = promise => {
  return promise.then(data => [null, data]).catch(err => [err, null])
}

/**
 * 处理空的参数
 * @param obj
 * @returns
 */
export const cleanParams = function (obj) {
  return Object.keys(obj).map(key => (obj[key] = ''))
}

/**
 * 生成uuid
 */
export const generateUUID = function () {
  var d = new Date().getTime()
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now() //use high-precision timer if available
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

/**
 * 生成随机编码
 * num // 位数
 */
export const generateCode = (num = 5) => {
  //random为在a和b见随机生成一个数
  const random = (a, b) => Math.round(Math.random() * (a - b) + b);
  let str = ''
  let a = String.fromCharCode(random(65, 90));//A-Z
  let b = String.fromCharCode(random(97, 122));//a-z	
  let c = String.fromCharCode(random(48, 57));//1-9
  for (let i = 0; i < num; i++) {
    var ranNum = Math.ceil(Math.random() * 25); //生成一个0到25的数字
    str += String.fromCharCode(65 + ranNum)
  }
  return str
}


/**
 * @desc 函数防抖
 * @param {Function} func 函数
 * @param {Number} wait 延迟执行毫秒数
 * @param {Boolean} immediate true 表示立即执行，false 表示非立即执行
 */
export const debounce = function (func, wait, immediate) {
  let timeout
  return function () {
    let context = this
    let args = arguments
    if (timeout) {
      clearTimeout(timeout)
    }
    if (immediate) {
      let callNow = !timeout
      timeout = setTimeout(() => {
        timeout = null
      }, wait)
      if (callNow) {
        typeof func === 'function' && func.apply(context, args)
      }
    } else {
      timeout = setTimeout(() => {
        typeof func === 'function' && func.apply(context, args)
      }, wait)
    }
  }
}

/**
 * @desc 函数节流
 * @param {Function} func 函数
 * @param {Number} wait 延迟执行毫秒数
 * @param {Boolean} type true 表示时间戳版，false 表示定时器版
 */
export const throttle = function (func, wait, type) {
  let previous
  let timeout
  if (type) {
    previous = 0
  } else {
    timeout
  }
  return function () {
    let context = this
    let args = arguments
    if (type) {
      let now = Date.now()
      if (now - previous > wait) {
        typeof func === 'function' && func.apply(context, args)
        previous = now
      }
    } else {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null
          typeof func === 'function' && func.apply(context, args)
        }, wait)
      }
    }
  }
}

/**
 * 处理时间格式为2位
 * @param {*} n 数字
 * @returns
 */
const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

/**
 * 格式化日期
 * @param {*} inputTime //时间戳
 * @param {*} type //日期类型 "yyyy-MM"
 * @returns
 */
export const formatDateTime = (inputTime, type) => {
  let date = new Date(inputTime)
  let y = date.getFullYear()
  let m = formatNumber(date.getMonth() + 1)
  let d = formatNumber(date.getDate())
  let h = formatNumber(date.getHours())
  let minute = formatNumber(date.getMinutes())
  let second = formatNumber(date.getSeconds())
  if (type == 'yyyy-MM') {
    return y + '-' + m
  }
  if (type == 'all') {
    return { y, m, d, h, minute, second }
  }
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
}

// 导出下载
export const reportReport = ({ file, fileName = "统计.xlsx" }) => {
  const blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }); // new Blob([res])中不加data就会返回下图中[objece objece]内容（少取一层）
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName; // 下载文件名称
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(link.href); // 释放URL 对象
}

/**
 * 表单验证错误，滚动
 * @param object 表单的object
 */
export const errScroll = (object) => {
  // 获取元素距离body顶部的距离
  let getTop = dom => {
    let top = dom.offsetTop
    // while括号里的值是 dom.offsetParent
    while ((dom = dom.offsetParent)) top = top + dom.offsetTop
    return top
  }
  let [first_prop] = Object.keys(object)
  let top = getTop(document.querySelector(`label[for='${first_prop}']`))
  document.querySelector('.el-main').scrollTo({ top: top - 100, behavior: 'smooth' })
  // window.scrollTo({ top: top, behavior: 'smooth' })

}

/**
 * 判断是否为空
 * @param {*} val
 * @returns
 */
export const isEmpty = (val) => {
  if (typeof val == "undefined" || val == null || val == "" || val == "undefined") {
    return true;
  }
  return false;
}