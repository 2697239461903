<template>
  <el-container class="_container">
    <el-header class="header">
      <div class="headerTitle">
        <span class="hightLight">{{ title }}</span>
        <span>管理后台</span>
      </div>
      <div class="header_user">
        <div class="avator_img">
          <img :src="logoInfo.img" alt="" />
        </div>
        <div class="avator_name">{{ logoInfo.name }}</div>
        <div class="vertical_line">|</div>
        <div class="logout" @click="handleLogout">退出</div>
      </div>
    </el-header>
    <el-container style="marginBottom:10px;overflow:hidden;">
      <AsideVue />
      <el-main>
        <router-view :key="$route.fullPath"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import { logout } from '../api/AdminController'
import TitleBarBaseVue from '../components/TitleBar/TitleBarBase.vue'
import AsideVue from './component/Aside.vue'
import { title } from '@/config'
export default {
  data() {
    return {
      title: title
    }
  },
  computed: {
    ...mapState({
      logoInfo: state => state.Login.loginInfo
    })
  },
  methods: {
    async handleLogout() {
      // let [err, res] = await logout()
      // if (err) {
      //   console.log(err)
      //   return this.$message.error(err.msg || '登出失败')
      // }
      // console.log(res)
      // this.$message.success('登出成功')
      this.$store.commit('Login/logout')
      this.$router.replace({ path: '/login' })
    }
  },
  components: {
    TitleBarBaseVue,
    AsideVue
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
/* 滚动条样式 start*/
::-webkit-scrollbar {
  width: 6px;
  height: 0;
}

/*滚动区域背景*/
::-webkit-scrollbar-track-piece {
  background-color: #fff;
  -webkit-border-radius: 6px;
}

/*竖向滚动条*/
::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: silver;
  -webkit-border-radius: 6px;
}

/*横向滚动条*/
::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: red;
  -webkit-border-radius: 6px;
}
/* 滚动条样式 end*/
</style>
