const DEFAULTIMG = require('@/assets/logo.jpg')
export default {
  namespaced: true,
  state: {
    defaultimg: DEFAULTIMG // 没有图片时默认的图片
  },
  mutations: {},
  actions: {},
  modules: {}
}
