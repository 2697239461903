import layout from '@/layout/index'
import { sessionStorage } from '@/utils/storage'

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    name: 'index',
    redirect: '/layout'
  },
  {
    path: '/layout',
    name: '布局',
    component: layout,
    redirect: sessionStorage.get('activePath') || '/userList',
    children: [
      {
        path: '/userList',
        name: '用户列表',
        meta: {
          title: '用户列表'
        },
        component: () => import('@/views/user/userList/index.vue')
      },
      {
        path: '/goldenReferList',
        name: '金牌推荐官列表',
        meta: {
          title: '金牌推荐官列表'
        },
        component: () => import('@/views/user/goldenReferList/index.vue')
      },
      {
        path: '/officialList',
        name: '官方列表',
        meta: {
          title: '官方列表'
        },
        component: () => import('@/views/user/officialList/index.vue')
      },
      {
        path: '/agentList',
        name: '代理列表',
        component: () => import('@/views/user/agentList/index.vue')
      },
      {
        path: '/hotelList',
        name: '酒店管理',
        component: () => import('@/views/user/hotelList/index.vue')
      },
      {
        path: '/userWalletList',
        name: '用户余额',
        meta: {
          title: '用户余额'
        },
        component: () => import('@/views/financialManage/userWalletList/index.vue')
      },
      {
        path: '/goldenWalletList',
        name: '金牌收益',
        meta: {
          title: '金牌收益'
        },
        component: () => import('@/views/financialManage/goldenWalletList/index.vue')
      },
      // 商品管理
      {
        path: '/goodsList',
        name: '商品管理',
        component: () => import('@/views/goods/goodsList/index.vue')
      },
      {
        path: '/authGoodsList',
        name: '授权商品',
        component: () => import('@/views/goods/authGoodsList/index.vue')
      },
      {
        path: '/goodsEdit',
        name: '商品编辑',
        component: () => import('@/views/goods/goodsEdit/index.vue')
      },
      {
        path: '/skuPreset',
        name: 'SKU预设',
        component: () => import('@/views/goods/skuPreset/index.vue')
      },
      {
        path: '/categoryList',
        name: '商品分类',
        component: () => import('@/views/goods/categoryList/index.vue')
      },
      {
        path: '/categoryEdit',
        name: '商品分类编辑',
        component: () => import('@/views/goods/categoryEdit/index.vue')
      },
      {
        path: '/iconList',
        name: 'ICON入口管理',
        component: () => import('@/views/goods/iconList/index.vue')
      },
      {
        path: '/groupBayList',
        name: '团购商品列表',
        component: () => import('@/views/groupBayMange/groupBayList/index.vue')
      },
      {
        path: '/groupBayEdit',
        name: '编辑团购商品',
        component: () => import('@/views/groupBayMange/groupBayEdit/index.vue')
      },
      // 审核列表
      {
        path: '/orderReview',
        name: '订单管理',
        component: () => import('@/views/audit/orderReview/index.vue')
      },
      // {
      //   path: '/orderAudit',
      //   name: '订单审核',
      //   component: () => import('@/views/audit/orderAudit/index.vue')
      // },
      {
        path: '/agentAudit',
        name: '代理审核',
        component: () => import('@/views/audit/agentAudit/index.vue')
      },
      {
        path: '/reviewAudit',
        name: '评价审核',
        component: () => import('@/views/audit/reviewAudit/index.vue')
      },
      {
        path: '/dockManage',
        name: '小程序对接管理',
        component: () => import('@/views/system/dockManage/index.vue')
      },
      {
        path: '/authManage',
        name: '授权管理',
        component: () => import('@/views/system/authManage/index.vue')
      },
      {
        path: '/systemSetting',
        name: '系统设置',
        component: () => import('@/views/system/systemSetting/index.vue')
      },
      {
        path: '/administrator',
        name: '管理员',
        component: () => import('@/views/system/administrator/index.vue')
      },
      {
        path: '/bannerlist',
        name: '轮播图设置',
        component: () => import('@/views/system/bannerlist/index.vue')
      },
      {
        path: '/brandInfoSeting',
        name: '品牌信息',
        component: () => import('@/views/system/brandInfoSeting/index.vue')
      },
      {
        path: '/commentsys',
        name: '评论管理员',
        component: () => import('@/views/system/commentSys/index.vue')
      },
      {
        path: '/authBusiness',
        name: '授权商家',
        meta: {
          title: '授权商家'
        },
        component: () => import('@/views/system/authBusiness/index.vue')
      },
      {
        path: '/commonQuestion',
        name: '常见问题',
        component: () => import('@/views/questionInfo/commonQuestion/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: '登陆',
    component: () => import('@/views/loginRegister/login/index'),
    hidden: true
  },
  {
    path: '/loginRegister',
    name: '登陆注册',
    component: () => import('@/views/loginRegister/index'),
    hidden: true
  },
  {
    path: '/aLIAuthResult',
    name: '授权结果',
    component: () => import('@/views/aLIAuthResult/index'),
    hidden: true
  },
  {
    path: '*',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/403',
    component: () => import('@/views/error-page/403'),
    hidden: true
  }
]
