import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import Login from './Login'
import Defaultimg from './Defaut'

Vue.use(Vuex)

export default new Vuex.Store({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  // modules: {
  // }
  modules: {
    Login,
    Defaultimg
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer: state => ({
        Login: state.Login,
        Defautimg: state.Defaultimg
      })
    })
  ] //加上这个就可以了
})
