import Vue from 'vue'
import VueRouter from 'vue-router'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store/index.js'
import { constantRouterMap } from './router.config.js'

Vue.use(VueRouter)
// 白名单
const routerWhiteList = ['/login', '/aLIAuthResult']

const router = new VueRouter({
  routes: constantRouterMap
})
// 路由开始进入
router.beforeEach((to, from, next) => {
  nprogress.start()
  var token = store.state.Login.loginInfo?.token
  // 判断token
  if (token) {
    if (to.path === '/login') {
      next('/')
      NProgress.done()
    } else {
      next()
    }
  } else {
    // 判断白名单
    console.log(routerWhiteList.includes(to.path))
    if (routerWhiteList.includes(to.path)) {
      next()
    } else {
      console.log('登录')
      next('/login')
    }
  }
})
// 路由结束进入
router.afterEach((to, from) => {
  nprogress.done()
})

export default router
