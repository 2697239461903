import Vue from 'vue'
import { MessageBox } from 'element-ui';

/**
 * 单行输入
 * 校验不可为空
 * @param {*} msg
 */
export const promptConfirm = (msg = '请输入密码') => {
  return new Promise((resolve, reject) => {
    MessageBox.prompt(msg, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /\S/,
      inputErrorMessage: '不能为空哦'
    }).then(({ action, value }) => {
      resolve(value)
    }).catch(err => err)
  })
}

Vue.prototype.$promptConfirm = promptConfirm
/**
 * 重复确认操作
 * @param {*} msg 
 */
export const repeatedConfirm = (msg = '是否确认此操作?') => {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(msg, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      resolve()
    }).catch(err => err)
  })
}

Vue.prototype.$repeatedConfirm = repeatedConfirm