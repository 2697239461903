export default {
  namespaced: true,
  state: {
    loginInfo: null
  },
  mutations: {
    saveLogin(state, n) {
      state.loginInfo = n
    },
    logout(state, n) {
      state.loginInfo = null
    }
  },
  actions: {}
}
